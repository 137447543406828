import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "redux/assets/actions";
import { store, history } from "redux/store";
import {
	deleteRequest,
	getRequest,
	patchRequest,
	postRequest,
	putRequest,
} from "helpers/axiosClient";
import {
	calculatePageNumber,
	getCompanyUrl,
	getLocaleMessages,
	handleException,
	updateSelectedModule,
	handleRenderPageException,
	customAPIRequest,
} from "redux/helper";
import {
	constructAssetsToken,
	fetchCurrentJourneyID,
	searchAssets,
	setSelectedAsset,
	showNotification,
	constructPageURL,
	searchTasks,
	searchTask,
	setSelectTask,
} from "components/utility/helper";
import { getRootUrl } from "components/utility/helper";
import journeyActions from "redux/journeys/actions";
import {
	assetMessage,
	assetTemplate,
	defaultPage,
	notFoundCode,
} from "components/constants";

export function* getMessageList(params) {
	try {
		const {
				pageSize,
				messageSortOrder,
				messageSortKey,
				messageCurrentPage,
				assetsActiveList,
				templatesActiveList,
				assetSearchText,
			} = store.getState().Assets,
			assetType =
				assetsActiveList === assetTemplate &&
				templatesActiveList === assetMessage
					? "email"
					: "",
			isTemplate =
				assetsActiveList === assetTemplate || params.isTemplate ? true : false;

		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/assets?query[name_cont]=${encodeURI(
					assetSearchText,
				)}&query[sorts][]=${messageSortKey} ${messageSortOrder}&query[sorts][]=id ${messageSortOrder}&page=${messageCurrentPage}&per_page=${pageSize}&query[asset_type_eq]=${assetType}&query[is_template_eq]=${isTemplate}`,
			),
		);
		yield put({
			type: actions.GET_MESSAGE_LIST_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_MESSAGE_LIST_FAILURE,
		});
	}
}

export function* deleteMessageList(params) {
	try {
		const {
			selectedMessageList,
			messageCurrentPage,
			totalMessageData,
			pageSize,
		} = store.getState().Assets;
		const response = yield call(() =>
			patchRequest(`${getCompanyUrl()}/assets`, {
				ids: selectedMessageList,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.DELETE_MESSAGE_LIST_SUCCESS,
		});
		if (
			messageCurrentPage >
			Math.ceil((totalMessageData - selectedMessageList.length) / pageSize)
		) {
			let pages = calculatePageNumber(
				totalMessageData,
				selectedMessageList,
				pageSize,
			);
			yield put({
				type: actions.SET_MESSAGE_TABLE_CURRENT_PAGE,
				payload: pages,
			});
		}
		yield put({
			type: actions.GET_MESSAGE_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.DELETE_MESSAGE_LIST_FAILURE,
		});
	}
}

export function* createNewEmail(params) {
	try {
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/assets`, {
				asset: params.payload,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.CREATE_NEW_EMAIL_OR_MESSAGE_SUCCESS,
			payload: response.data.data,
		});
		if (params.payload.asset_type === "sms") {
			yield put({
				type: actions.RESET_MESSAGE_DATA,
			});
			yield put({
				type: actions.GET_MESSAGE_LIST,
			});
			if (params.isFromJourney) {
				setSelectedAsset(response.data.data.asset);
				searchAssets(defaultPage, params.payload.name, true);
				updateSelectedModule(
					response.data.data.asset.id,
					params.payload.asset_type,
				);
				yield put({
					type: journeyActions.UPDATE_ADD_MODULE_DRAWER_INFO,
					payload: true,
					moduleType: params.payload.asset_type,
					page: defaultPage,
				});
			} else {
				history.push({
					pathname: "assets",
					search: "asset_type=message",
				});
			}
		} else {
			history.push({
				pathname: `/${getRootUrl()}/assets/${
					response.data.data.asset.id
				}/message`,
				state: {
					isFromJourney: params.isFromJourney,
					journeyId: fetchCurrentJourneyID(),
				},
				search: params.params ? params.params : "",
			});
		}
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.CREATE_NEW_EMAIL_OR_MESSAGE_FAILURE,
		});
	}
}
export function* getEmailData(params) {
	try {
		const { assetsActiveList } = store.getState().Assets;
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/assets/${params.payload}`),
		);
		if (response.data.data.asset.asset_type !== "email") {
			history.push({
				pathname: `/${getRootUrl()}/assets`,
				search: `asset_type=${assetsActiveList}`,
			});
			showNotification({
				type: "error",
				message: getLocaleMessages("message.wrongAssetType"),
				description: getLocaleMessages("description.wrongAssetType"),
			});
		} else {
			yield put({
				type: actions.GET_ASSETS_TOKEN,
				payload: "email",
			});
			yield put({
				type: actions.GET_EMAIL_DATA_SUCCESS,
				payload: response.data.data,
			});
		}
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_EMAIL_DATA_FAILURE,
		});
	}
}

export function* updateEmailContent(params) {
	try {
		const { assetID, formLoader } = store.getState().Assets;
		const response = yield call(() =>
			patchRequest(`${getCompanyUrl()}/assets/${assetID}`, {
				asset: params.payload,
			}),
		);
		if (formLoader) {
			showNotification({
				type: "success",
				message: getLocaleMessages("label.success"),
				description: response.data.data.message,
			});
		}
		if (params.payload.is_simple_email) {
			showNotification({
				type: "success",
				message: getLocaleMessages("label.success"),
				description: response.data.data.message,
			});
		}
		yield put({
			type: actions.UPDATE_EMAIL_OR_MESSAGE_CONTENT_SUCCESS,
			payload: {
				...response.data.data,
				isSimpleEmail: params.payload.is_simple_email ?? false,
			},
		});
		if (params.payload.asset_type === "sms") {
			yield put({
				type: actions.RESET_MESSAGE_DATA,
			});
			yield put({
				type: actions.GET_MESSAGE_LIST,
			});
		}
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_EMAIL_OR_MESSAGE_CONTENT_FAILURE,
		});
	}
}

export function* getAssetToken(params) {
	try {
		const response = yield call(() => getRequest(`${getCompanyUrl()}/tokens`));
		yield put({
			type: actions.GET_ASSETS_TOKEN_SUCCESS,
			payload:
				params.payload === "email"
					? constructAssetsToken(response.data.data.tokens)
					: response.data.data.tokens,
		});
	} catch (error) {
		yield put({
			type: actions.GET_ASSETS_TOKEN_FAILURE,
		});
	}
}

export function* getPagesList(params) {
	try {
		const {
				pagesPageSize,
				pagesSortOrder,
				pagesSortKey,
				pagesCurrentPage,
				assetsActiveList,
				assetSearchText,
			} = store.getState().Assets,
			isTemplate = assetsActiveList === assetTemplate ? true : false;
		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/pages?query[name_cont]=${encodeURI(
					assetSearchText,
				)}&query[sorts][]=${pagesSortKey} ${pagesSortOrder}&query[sorts][]=id ${pagesSortOrder}&page=${pagesCurrentPage}&per_page=${pagesPageSize}&query[page_type_eq]=asset&query[is_template_eq]=${isTemplate}`,
			),
		);
		yield put({
			type: actions.GET_PAGES_LIST_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_PAGES_LIST_FAILURE,
		});
	}
}

export function* deletePagesList(params) {
	try {
		const {
			selectedPagesList,
			pagesCurrentPage,
			totalPagesData,
			pagesPageSize,
		} = store.getState().Assets;
		const response = yield call(() =>
			putRequest(`${getCompanyUrl()}/pages`, {
				ids: selectedPagesList,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		if (
			pagesCurrentPage >
			Math.ceil((totalPagesData - selectedPagesList.length) / pagesPageSize)
		) {
			let pages = calculatePageNumber(
				totalPagesData,
				selectedPagesList,
				pagesPageSize,
			);
			yield put({
				type: actions.SET_PAGES_TABLE_CURRENT_PAGE,
				payload: pages,
			});
		}
		yield put({
			type: actions.DELETE_PAGES_LIST_SUCCESS,
		});
		yield put({
			type: actions.GET_PAGES_LIST,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.DELETE_PAGES_LIST_FAILURE,
		});
	}
}

export function* createNewPages(params) {
	try {
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/pages`, {
				page: {
					...params.payload,
					page_type: "asset",
				},
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.CREATE_NEW_PAGE_SUCCESS,
			payload: response.data.data,
		});
		history.push({
			pathname: `/${getRootUrl()}/assets/${response.data.data.page.id}/page`,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.CREATE_NEW_PAGE_FAILURE,
		});
	}
}

export function* getPagesData(params) {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/pages/${params.payload}`),
		);
		yield put({
			type: actions.GET_PAGE_DATA_SUCCESS,
			payload: response.data.data,
		});
		yield put({
			type: actions.GET_ASSETS_TOKEN,
			payload: "email",
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_PAGE_DATA_FAILURE,
		});
	}
}

export function* updatePagesContent(params) {
	try {
		const { assetID, formLoader } = store.getState().Assets;
		const response = yield call(() =>
			putRequest(`${getCompanyUrl()}/pages/${assetID}`, {
				page: params.payload,
			}),
		);
		if (formLoader) {
			showNotification({
				type: "success",
				message: getLocaleMessages("label.success"),
				description: response.data.data.message,
			});
		}
		yield put({
			type: actions.UPDATE_PAGE_CONTENT_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_PAGE_CONTENT_FAILURE,
		});
	}
}

export function* getPageRendering(params) {
	try {
		const response = yield call(() => getRequest(`pages/${params.payload}`));
		yield put({
			type: actions.GET_PAGE_RENDERING_DATA_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleRenderPageException(error);
		yield put({
			type: actions.GET_PAGE_RENDERING_DATA_FAILURE,
		});
	}
}

export function* getPagesURL(params) {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/pages/names?query[page_type_eq]=asset`),
		);
		yield put({
			type: actions.GET_PAGE_URL_LIST_SUCCESS,
			payload:
				params.payload === "email"
					? response.data.data.pages.length
						? constructPageURL(response.data.data.pages, false)
						: []
					: response.data.data.pages,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_PAGE_URL_LIST_FAILURE,
		});
	}
}

export function* duplicateEmail(params) {
	try {
		const { assetID } = store.getState().Assets;
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/assets/${assetID}/duplicate`, {
				asset: params.payload,
			}),
		);
		yield put({
			type: actions.EMAIL_DUPLICATE_SUCCESS,
		});
		history.push({
			pathname: `/${getRootUrl()}/assets/${
				response.data.data.asset.id
			}/message`,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.EMAIL_DUPLICATE_FAILURE,
		});
	}
}

export function* duplicatePage(params) {
	try {
		const { assetID } = store.getState().Assets;
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/pages/${assetID}/duplicate`, {
				page: params.payload,
			}),
		);
		yield put({
			type: actions.PAGE_DUPLICATE_SUCCESS,
		});
		history.push({
			pathname: `/${getRootUrl()}/assets/${response.data.data.page.id}/page`,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.PAGE_DUPLICATE_FAILURE,
		});
	}
}

export function* createNewTemplate(params) {
	try {
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/templates`, {
				template: params.payload,
			}),
		);
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
		yield put({
			type: actions.CREATE_NEW_ASSET_TEMPLATE_SUCCESS,
			payload: response.data.data,
		});
		history.push(`assets/${response.data.data.template.id}/template`);
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.CREATE_NEW_ASSET_TEMPLATE_FAILURE,
		});
	}
}

export function* getTemplateData(params) {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/templates/${params.payload}`),
		);
		yield put({
			type: actions.GET_ASSET_TEMPLATE_DATA_SUCCESS,
			payload: response.data.data,
		});
		yield put({
			type: actions.GET_ASSETS_TOKEN,
			payload: "email",
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_ASSET_TEMPLATE_DATA_FAILURE,
		});
	}
}

export function* updateTemplateContent(params) {
	try {
		const { assetID, formLoader } = store.getState().Assets;
		const response = yield call(() =>
			patchRequest(`${getCompanyUrl()}/templates/${assetID}`, {
				template: params.payload,
			}),
		);
		if (formLoader) {
			showNotification({
				type: "success",
				message: getLocaleMessages("label.success"),
				description: response.data.data.message,
			});
		}
		yield put({
			type: actions.UPDATE_ASSET_TEMPLATE_CONTENT_SUCCESS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.UPDATE_ASSET_TEMPLATE_CONTENT_FAILURE,
		});
	}
}

export function* getTasks() {
	try {
		const {
			tasksPageSize,
			tasksSortOrder,
			tasksSortKey,
			tasksCurrentPage,
			assetSearchText,
		} = store.getState().Assets;
		const { data } = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/tasks?query[name_cont]=${encodeURI(
					assetSearchText,
				)}&query[sorts][]=${tasksSortKey} ${tasksSortOrder}&query[sorts][]=id ${tasksSortOrder}&page=${tasksCurrentPage}&per_page=${tasksPageSize}`,
			),
		);
		yield put({
			type: actions.FETCH_TASKS_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_TASKS_FAILURE,
		});
	}
}

export function* getTaskData(params) {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/tasks/${params.payload}`),
		);
		yield put({
			type: actions.GET_TASK_DATA_SUCCESS,
			payload: data.data.task,
		});
		yield put({
			type: actions.GET_ASSETS_TOKEN,
			payload: "email",
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_TASK_DATA_FAILURE,
		});
	}
}

export function* deleteTask() {
	try {
		const { selectedTaskList, tasksCurrentPage, totalTasks, tasksPageSize } =
			store.getState().Assets;
		const response = yield call(() =>
			patchRequest(`${getCompanyUrl()}/tasks`, {
				ids: selectedTaskList,
			}),
		);
		showNotification({
			type: "success",
			message: response.data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({
			type: actions.DELETE_TASKS_SUCCESS,
		});
		if (
			tasksCurrentPage >
			Math.ceil((totalTasks - selectedTaskList.length) / tasksPageSize)
		) {
			let pages = calculatePageNumber(
				totalTasks,
				selectedTaskList,
				tasksPageSize,
			);
			yield put({
				type: actions.SET_TASK_TABLE_CURRENT_PAGE,
				payload: pages,
			});
		}
		yield put({
			type: actions.FETCH_TASKS,
		});
	} catch (error) {
		handleException(error);
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.DELETE_MESSAGE_CONFIRMATION_MODAL_VISIBLE,
				payload: false,
			});
		}
		yield put({
			type: actions.DELETE_TASKS_FAILURE,
		});
	}
}
export function* createTask(params) {
	try {
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/tasks`, {
				task: params.payload,
			}),
		);
		showNotification({
			type: "success",
			message: response.data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({
			type: actions.CREATE_TASK_SUCCESS,
		});
		if (params.isFromJourney) {
			searchTasks(defaultPage, params.payload.name, true);
		} else if (params.isFromEmployee) {
			searchTask(defaultPage, params.payload.name, true);
			setSelectTask(response.data.data.task.id);
		}
		history.push({
			pathname: `/${getRootUrl()}/assets/${
				response.data.data.task.id
			}/tasks/builder`,
			state: {
				isFromJourney: params.isFromJourney,
				journeyId: fetchCurrentJourneyID(),
				isFromEmployee: params.isFromEmployee,
				importId: store.getState().Employees.currentImportId,
			},
			search: params.params ? params.params : "",
		});
	} catch (error) {
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.SET_TASK_DRAWER_VISIBLE,
				payload: {
					visible: false,
					formType: "create",
				},
			});
		}
		handleException(error);
		yield put({
			type: actions.CREATE_TASK_FAILURE,
		});
	}
}

export function* updateTask(params) {
	try {
		const { currentTaskId, createTaskLoader } = store.getState().Assets;
		const { data } = yield call(() =>
			putRequest(`${getCompanyUrl()}/tasks/${currentTaskId}`, {
				task: params.payload,
			}),
		);
		if (createTaskLoader) {
			showNotification({
				type: "success",
				message: data.data.message,
				description: getLocaleMessages("label.successDescription"),
			});
		}
		yield put({
			type: actions.UPDATE_TASK_SUCCESS,
			payload: data.data.task,
		});
	} catch (error) {
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.SET_TASK_DRAWER_VISIBLE,
				payload: {
					visible: false,
					formType: "create",
				},
			});
		}
		handleException(error);
		yield put({
			type: actions.UPDATE_TASK_FAILURE,
		});
	}
}

export function* getDrawerTemplateList(params) {
	try {
		const { pageSize, currentPage } = params.payload;
		const response = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/${
					params.templateType
				}?query[sorts][]=title asc&query[sorts][]=id asc&page=${currentPage}&per_page=${pageSize}&query[is_template_eq]=true`,
			),
		);
		yield put({
			type: actions.GET_SELECT_TEMPLATE_LIST_SUCCESS,
			payload: response.data.data[params.templateType],
			totalRecords: response.data.data.total_records,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.GET_SELECT_TEMPLATE_LIST_FAILURE,
		});
	}
}

export function* getTasksTemplate() {
	try {
		const {
			tasksTemplatePageSize,
			tasksTemplateSortOrder,
			tasksTemplateSortKey,
			tasksTemplateCurrentPage,
			assetSearchText,
		} = store.getState().Assets;
		const { data } = yield call(() =>
			getRequest(
				`admin/tasks?query[name_cont]=${encodeURI(
					assetSearchText,
				)}&query[sorts][]=${tasksTemplateSortKey} ${tasksTemplateSortOrder}&query[sorts][]=id ${tasksTemplateSortOrder}&page=${tasksTemplateCurrentPage}&per_page=${tasksTemplatePageSize}&query[published_eq]=true`,
			),
		);
		yield put({
			type: actions.FETCH_TASKS_TEMPLATE_SUCCESS,
			payload: data.data,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.FETCH_TASKS_TEMPLATE_FAILURE,
		});
	}
}

export function* duplicateTask() {
	try {
		const { taskTemplateId } = store.getState().Assets;
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/tasks/${taskTemplateId}/duplicate`),
		);
		yield put({
			type: actions.DUPLICATE_TASK_TEMPLATE_SUCCESS,
		});
		history.push({
			pathname: `/${getRootUrl()}/assets/${
				response.data.data.task.id
			}/tasks/builder`,
		});
	} catch (error) {
		// hide modal for 404 response
		if (error.response.status === notFoundCode) {
			yield put({
				type: actions.TASK_DUPLICATE_MODAL_VISIBLE,
				payload: false,
			});
		}
		handleException(error);
		yield put({
			type: actions.DUPLICATE_TASK_TEMPLATE_FAILURE,
		});
	}
}

export function* removeUploadedFilesEmail(params) {
	try {
		const { data } = yield call(() =>
			patchRequest(`${getCompanyUrl()}/assets/destroy_attachments`, {
				ids: params.payload,
			}),
		);
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({
			type: actions.REMOVE_EMAIL_UPLOADED_FILES_SUCCESS,
			payload: params.payload,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.REMOVE_EMAIL_UPLOADED_FILES_FAILURE,
		});
	}
}

export function* removeUploadedFilesTask(params) {
	try {
		const { data } = yield call(() =>
			patchRequest(`${getCompanyUrl()}/tasks/destroy_attachments`, {
				ids: params.payload,
			}),
		);
		showNotification({
			type: "success",
			message: data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({
			type: actions.REMOVE_TASK_UPLOADED_FILES_SUCCESS,
			payload: params.payload,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.REMOVE_TASK_UPLOADED_FILES_FAILURE,
		});
	}
}

export function* uploadImageFile(params) {
	try {
		const { data } = yield call(() =>
			postRequest("direct_uploads", {
				blob: params.payload.blob,
				isPublicAccessible: params.payload.isPublicAccessible || false,
			}),
		);
		yield put({
			type: actions.UPLOAD_ASSET_IMAGE_TO_S3,
			payload: {
				URL: data.data.direct_upload.url,
				headers: data.data.direct_upload.headers,
				signed_id: data.data.direct_upload.signed_id,
				fileData: params.payload.fileData,
			},
			callBackAction: params.callBackAction,
		});
	} catch (error) {
		yield put({
			type: actions.SET_ASSET_FILE_PROGRESS_STATUS,
			payload: 0,
		});
		handleException(error);
	}
}

export function* uploadToS3(params) {
	try {
		yield call(() => customAPIRequest({ ...params.payload }));
		params.callBackAction(params.payload.signed_id);
	} catch (error) {
		yield put({
			type: actions.SET_ASSET_FILE_PROGRESS_STATUS,
			payload: 0,
		});
		handleException(error);
	}
}

export function* createTicketingAsset(params) {
	try {
		const { ticketingAssets } = store.getState().Assets;
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/ticketing_assets`, params.payload),
		);
		showNotification({
			type: "success",
			message: response.data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		const updatedTicketingAssets = [
			response.data.data.ticketing_asset,
			...ticketingAssets,
		];
		yield put({
			type: actions.SET_TICKETING_ASSETS,
			payload: updatedTicketingAssets,
		});
		if (params.isFromJourney) {
			updateSelectedModule(
				response.data.data.ticketing_asset.id,
				params.payload.asset_type,
			);
			yield put({
				type: journeyActions.UPDATE_ADD_MODULE_DRAWER_INFO,
				payload: true,
				moduleType: params.payload.asset_type,
				page: defaultPage,
			});
		}
		yield put({
			type: actions.SET_TICKETING_DRAWER_VISIBLE,
			payload: {
				visible: false,
			},
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchTrello() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/ticketing_assets`),
		);
		yield put({
			type: actions.SET_TICKETING_ASSETS,
			payload: response.data.data.ticketing_assets,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* deleteTrelloAssetList() {
	try {
		const { selectedTrelloAssetsList } = store.getState().Assets;
		const response = yield call(() =>
			patchRequest(`${getCompanyUrl()}/ticketing_assets`, {
				ids: selectedTrelloAssetsList,
			}),
		);
		showNotification({
			type: "success",
			message: response.data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
		yield put({
			type: actions.DELETE_MESSAGE_CONFIRMATION_MODAL_VISIBLE,
			payload: false,
		});
		yield put({ type: actions.FETCH_TICKETING });
		yield put({
			type: actions.TRELLO_ASSETS_SELECTED_LIST,
			payload: [],
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchMerges() {
	try {
		const response = yield call(() => getRequest(`${getCompanyUrl()}/merges`));
		yield put({
			type: actions.SET_PROJECT_LIST,
			payload: response.data.merges,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* createSlackAsset(params) {
	try {
		const { slackAssets } = store.getState().Assets;
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/slack_assets`, params.payload),
		);
		const updatedSlackAssets = [response.data.data.slack_asset, ...slackAssets];
		yield put({
			type: actions.SET_SLACK_ASSETS,
			payload: updatedSlackAssets,
		});
		if (params.isFromJourney) {
			updateSelectedModule(
				response.data.slack_asset.id,
				params.payload.asset_type,
			);
			yield put({
				type: journeyActions.UPDATE_ADD_MODULE_DRAWER_INFO,
				payload: true,
				moduleType: params.payload.asset_type,
				page: defaultPage,
			});
		}
		showNotification({
			type: "success",
			message: "Successfully created",
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
	}
}

export function* updateSlackAsset(params) {
	try {
		const response = yield call(() =>
			patchRequest(
				`${getCompanyUrl()}/slack_assets/${params.payload.slack_assets.id}`,
				params.payload,
			),
		);
		yield put({
			type: actions.FETCH_SLACK_ASSETS,
		});
		showNotification({
			type: "success",
			message: "Successfully updated",
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchSlackAsset() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/slack_assets`),
		);
		yield put({
			type: actions.SET_SLACK_ASSETS,
			payload: response.data.slack_assets,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* deleteSlackAssets() {
	try {
		const { selectedSlackAssets } = store.getState().Assets;
		const response = yield call(() =>
			patchRequest(`${getCompanyUrl()}/slack_assets`, {
				ids: selectedSlackAssets,
			}),
		);
		yield put({
			type: actions.FETCH_SLACK_ASSETS,
			payload: response.data.slack_assets,
		});
		yield put({
			type: actions.DELETE_MESSAGE_CONFIRMATION_MODAL_VISIBLE,
			payload: false,
		});
		showNotification({
			type: "success",
			message: response.data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchTicketingMerges() {
	try {
		const { data } = yield call(() =>
			getRequest(`${getCompanyUrl()}/merges/fetch_ticketing`),
		);
		yield put({
			type: actions.SET_TICKETING_MERGES,
			payload: data.merges,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* createMicrosoftTeamAsset(params) {
	try {
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/microsoft_team_assets`, params.payload),
		);
		store.dispatch({
			type: actions.FETCH_MICROSOFT_TEAM_ASSETS,
		});
		showNotification({
			type: "success",
			message: "Successfully created",
			description: getLocaleMessages("label.successDescription"),
		});
		if (params.isFromJourney) {
			updateSelectedModule(
				response.data.data.microsoft_team_asset.id,
				params.payload.asset_type,
			);
			yield put({
				type: journeyActions.UPDATE_ADD_MODULE_DRAWER_INFO,
				payload: true,
				moduleType: params.payload.asset_type,
				page: defaultPage,
			});
		}
		yield put({
			type: actions.SET_MICROSOFT_TEAM_DRAWER,
			payload: { visible: false },
		});
	} catch (error) {
		handleException(error);
	}
}

export function* updateMicrosoftTeamAsset(params) {
	try {
		yield call(() =>
			putRequest(
				`${getCompanyUrl()}/microsoft_team_assets/${
					params.payload.microsoft_team_assets.id
				}`,
				params.payload,
			),
		);
		store.dispatch({
			type: actions.FETCH_MICROSOFT_TEAM_ASSETS,
		});
		showNotification({
			type: "success",
			message: "Successfully updated",
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
	}
}

export function* fetchMicrosoftTeamAssets() {
	try {
		const response = yield call(() =>
			getRequest(`${getCompanyUrl()}/microsoft_team_assets`),
		);
		yield put({
			type: actions.SET_MICROSOFT_TEAM_ASSETS,
			payload: response.data.data,
		});
	} catch (error) {
		handleException(error);
	}
}

export function* deleteMicrosoftTeamList() {
	try {
		const { selectedMicrosoftTeamAssetsList } = store.getState().Assets;
		const response = yield call(() =>
			patchRequest(`${getCompanyUrl()}/microsoft_team_assets`, {
				ids: selectedMicrosoftTeamAssetsList,
			}),
		);
		store.dispatch({
			type: actions.FETCH_MICROSOFT_TEAM_ASSETS,
		});
		yield put({
			type: actions.DELETE_MESSAGE_CONFIRMATION_MODAL_VISIBLE,
			payload: false,
		});
		showNotification({
			type: "success",
			message: response.data.data.message,
			description: getLocaleMessages("label.successDescription"),
		});
	} catch (error) {
		handleException(error);
	}
}

export function* searchEmployees(params) {
	try {
		const { searchQuery } = params.payload;
		const { data } = yield call(() =>
			getRequest(
				`${getCompanyUrl()}/employees?query[email_or_name_cont]=${encodeURIComponent(
					searchQuery.trim(),
				)}`,
			),
		);
		const employees = data.data.employees.map((emp) => {
			return {
				id: emp.id,
				name: `${emp.first_name} ${emp.last_name}`,
				email: emp.email,
			};
		});
		yield put({
			type: actions.SET_SEARCHED_EMPLOYEES,
			payload: employees,
		});
		yield put({
			type: actions.SET_EMPLOYEE_SEARCH_LOADER,
			payload: false,
		});
	} catch (error) {
		handleException(error);
		yield put({
			type: actions.SET_EMPLOYEE_SEARCH_LOADER,
			payload: false,
		});
	}
}

export function* sendSampleAsset(params) {
	try {
		const { assetId, employeeIds } = params.payload;
		const response = yield call(() =>
			postRequest(`${getCompanyUrl()}/assets/${assetId}/send_sample`, {
				employee_ids: employeeIds,
			}),
		);
		yield put({
			type: actions.SET_SEND_SAMPLE_MODAL_VISIBLE,
			payload: false,
		});
		yield put({
			type: actions.SET_IS_SENDING_SAMPLE_ASSET,
			payload: false,
		});
		showNotification({
			type: "success",
			message: getLocaleMessages("label.success"),
			description: response.data.data.message,
		});
	} catch (error) {
		yield put({
			type: actions.SET_IS_SENDING_SAMPLE_ASSET,
			payload: false,
		});
		handleException(error);
	}
}
export default function* rootSaga() {
	yield all([
		takeEvery(actions.GET_MESSAGE_LIST, getMessageList),
		takeEvery(actions.DELETE_MESSAGE_LIST, deleteMessageList),
		takeEvery(actions.CREATE_NEW_EMAIL_OR_MESSAGE, createNewEmail),
		takeEvery(actions.GET_EMAIL_DATA, getEmailData),
		takeEvery(actions.UPDATE_EMAIL_OR_MESSAGE_CONTENT, updateEmailContent),
		takeEvery(actions.GET_ASSETS_TOKEN, getAssetToken),
		takeEvery(actions.GET_PAGES_LIST, getPagesList),
		takeEvery(actions.DELETE_PAGES_LIST, deletePagesList),
		takeEvery(actions.CREATE_NEW_PAGE, createNewPages),
		takeEvery(actions.GET_PAGE_DATA, getPagesData),
		takeEvery(actions.UPDATE_PAGE_CONTENT, updatePagesContent),
		takeEvery(actions.GET_PAGE_RENDERING_DATA, getPageRendering),
		takeEvery(actions.GET_PAGE_URL_LIST, getPagesURL),
		takeEvery(actions.EMAIL_DUPLICATE, duplicateEmail),
		takeEvery(actions.PAGE_DUPLICATE, duplicatePage),
		takeEvery(actions.CREATE_NEW_ASSET_TEMPLATE, createNewTemplate),
		takeEvery(actions.GET_ASSET_TEMPLATE_DATA, getTemplateData),
		takeEvery(actions.UPDATE_ASSET_TEMPLATE_CONTENT, updateTemplateContent),
		takeEvery(actions.FETCH_TASKS, getTasks),
		takeEvery(actions.GET_TASK_DATA, getTaskData),
		takeEvery(actions.DELETE_TASKS, deleteTask),
		takeEvery(actions.CREATE_TASK, createTask),
		takeEvery(actions.UPDATE_TASK, updateTask),
		takeEvery(actions.GET_SELECT_TEMPLATE_LIST, getDrawerTemplateList),
		takeEvery(actions.FETCH_TASKS_TEMPLATE, getTasksTemplate),
		takeEvery(actions.DUPLICATE_TASK_TEMPLATE, duplicateTask),
		takeEvery(actions.REMOVE_EMAIL_UPLOADED_FILES, removeUploadedFilesEmail),
		takeEvery(actions.REMOVE_TASK_UPLOADED_FILES, removeUploadedFilesTask),
		takeEvery(actions.UPLOAD_ASSET_IMAGE, uploadImageFile),
		takeEvery(actions.UPLOAD_ASSET_IMAGE_TO_S3, uploadToS3),
		takeEvery(actions.CREATE_TRELLO_ASSET, createTicketingAsset),
		takeEvery(actions.FETCH_TICKETING, fetchTrello),
		takeEvery(actions.DELETE_TRELLO_ASSETS_LIST, deleteTrelloAssetList),
		takeEvery(actions.FETCH_PROJECTS, fetchMerges),
		takeEvery(actions.CREATE_SLACK_ASSET, createSlackAsset),
		takeEvery(actions.UPDATE_SLACK_ASSET, updateSlackAsset),
		takeEvery(actions.FETCH_SLACK_ASSETS, fetchSlackAsset),
		takeEvery(actions.DELETE_SLACK_ASSETS_LIST, deleteSlackAssets),
		takeEvery(actions.FETCH_TICKETING_MERGES, fetchTicketingMerges),
		takeEvery(actions.CREATE_MICROSOFT_TEAM_ASSET, createMicrosoftTeamAsset),
		takeEvery(actions.FETCH_MICROSOFT_TEAM_ASSETS, fetchMicrosoftTeamAssets),
		takeEvery(actions.DELETE_MICROSOFT_TEAM_LIST, deleteMicrosoftTeamList),
		takeEvery(actions.UPDATE_MICROSOFT_TEAM_ASSET, updateMicrosoftTeamAsset),
		takeEvery(actions.SEARCH_EMPLOYEES, searchEmployees),
		takeEvery(actions.SEND_SAMPLE_ASSET, sendSampleAsset),
	]);
}
