const actions = {
	GET_MESSAGE_LIST: "GET_MESSAGE_LIST",
	GET_MESSAGE_LIST_SUCCESS: "GET_MESSAGE_LIST_SUCCESS",
	GET_MESSAGE_LIST_FAILURE: "GET_MESSAGE_LIST_FAILURE",
	SET_EMAIL_DRAWER_VISIBLE: "SET_EMAIL_DRAWER_VISIBLE",
	SET_MESSAGE_DRAWER_VISIBLE: "SET_MESSAGE_DRAWER_VISIBLE",
	MESSAGE_SELECTED_LIST: "MESSAGE_SELECTED_LIST",
	DELETE_MESSAGE_CONFIRMATION_MODAL_VISIBLE:
		"DELETE_MESSAGE_CONFIRMATION_MODAL_VISIBLE",
	DELETE_MESSAGE_LIST: "DELETE_MESSAGE_LIST",
	DELETE_MESSAGE_LIST_SUCCESS: "DELETE_MESSAGE_LIST_SUCCESS",
	DELETE_MESSAGE_LIST_FAILURE: "DELETE_MESSAGE_LIST_FAILURE",
	SET_MESSAGE_TABLE_SIZE: "SET_MESSAGE_TABLE_SIZE",
	SET_MESSAGE_TABLE_CURRENT_PAGE: "SET_MESSAGE_TABLE_CURRENT_PAGE",
	SET_MESSAGE_TABLE_SORT_ORDER: "SET_MESSAGE_TABLE_SORT_ORDER",
	SET_ASSETS_ACTIVE_LIST: "SET_ASSETS_ACTIVE_LIST",
	CREATE_NEW_EMAIL_OR_MESSAGE: "CREATE_NEW_EMAIL_OR_MESSAGE",
	CREATE_NEW_EMAIL_OR_MESSAGE_SUCCESS: "CREATE_NEW_EMAIL_OR_MESSAGE_SUCCESS",
	CREATE_NEW_EMAIL_OR_MESSAGE_FAILURE: "CREATE_NEW_EMAIL_OR_MESSAGE_FAILURE",
	GET_EMAIL_DATA: "GET_EMAIL_DATA",
	GET_EMAIL_DATA_SUCCESS: "GET_EMAIL_DATA_SUCCESS",
	GET_EMAIL_DATA_FAILURE: "GET_EMAIL_DATA_FAILURE",
	UPDATE_EMAIL_OR_MESSAGE_CONTENT: "UPDATE_EMAIL_OR_MESSAGE_CONTENT",
	UPDATE_EMAIL_OR_MESSAGE_CONTENT_SUCCESS:
		"UPDATE_EMAIL_OR_MESSAGE_CONTENT_SUCCESS",
	UPDATE_EMAIL_OR_MESSAGE_CONTENT_FAILURE:
		"UPDATE_EMAIL_OR_MESSAGE_CONTENT_FAILURE",
	RESET_EMAIL_DATA: "RESET_EMAIL_DATA",
	GET_ASSETS_TOKEN: "GET_ASSETS_TOKEN",
	GET_ASSETS_TOKEN_SUCCESS: "GET_ASSETS_TOKEN_SUCCESS",
	GET_ASSETS_TOKEN_FAILURE: "GET_ASSETS_TOKEN_FAILURE",
	SET_MESSAGE_DATA: "SET_MESSAGE_DATA",
	RESET_MESSAGE_DATA: "RESET_MESSAGE_DATA",
	GET_PAGES_LIST: "GET_PAGES_LIST",
	GET_PAGES_LIST_SUCCESS: "GET_PAGES_LIST_SUCCESS",
	GET_PAGES_LIST_FAILURE: "GET_PAGES_LIST_FAILURE",
	SET_PAGES_TABLE_SIZE: "SET_PAGES_TABLE_SIZE",
	SET_PAGES_TABLE_CURRENT_PAGE: "SET_PAGES_TABLE_CURRENT_PAGE",
	SET_PAGES_TABLE_SORT_ORDER: "SET_PAGES_TABLE_SORT_ORDER",
	PAGES_SELECTED_LIST: "PAGES_SELECTED_LIST",
	CREATE_NEW_PAGE: "CREATE_NEW_PAGE",
	CREATE_NEW_PAGE_SUCCESS: "CREATE_NEW_PAGE_SUCCESS",
	CREATE_NEW_PAGE_FAILURE: "CREATE_NEW_PAGE_FAILURE",
	GET_PAGE_DATA: "GET_PAGE_DATA",
	GET_PAGE_DATA_SUCCESS: "GET_PAGE_DATA_SUCCESS",
	GET_PAGE_DATA_FAILURE: "GET_PAGE_DATA_FAILURE",
	UPDATE_PAGE_CONTENT: "UPDATE_PAGE_CONTENT",
	UPDATE_PAGE_CONTENT_SUCCESS: "UPDATE_PAGE_CONTENT_SUCCESS",
	UPDATE_PAGE_CONTENT_FAILURE: "UPDATE_PAGE_CONTENT_FAILURE",
	SET_PAGES_DRAWER_VISIBLE: "SET_PAGES_DRAWER_VISIBLE",
	DELETE_PAGES_LIST: "DELETE_PAGES_LIST",
	DELETE_PAGES_LIST_SUCCESS: "DELETE_PAGES_LIST_SUCCESS",
	DELETE_PAGES_LIST_FAILURE: "DELETE_PAGES_LIST_FAILURE",
	GET_PAGE_RENDERING_DATA: "GET_PAGE_RENDERING_DATA",
	GET_PAGE_RENDERING_DATA_SUCCESS: "GET_PAGE_RENDERING_DATA_SUCCESS",
	GET_PAGE_RENDERING_DATA_FAILURE: "GET_PAGE_RENDERING_DATA_FAILURE",
	RESET_PAGE_RENDERING: "RESET_PAGE_RENDERING",
	GET_PAGE_URL_LIST: "GET_PAGE_URL_LIST",
	GET_PAGE_URL_LIST_SUCCESS: "GET_PAGE_URL_LIST_SUCCESS",
	GET_PAGE_URL_LIST_FAILURE: "GET_PAGE_URL_LIST_FAILURE",
	EMAIL_DUPLICATE: "EMAIL_DUPLICATE",
	EMAIL_DUPLICATE_SUCCESS: "EMAIL_DUPLICATE_SUCCESS",
	EMAIL_DUPLICATE_FAILURE: "EMAIL_DUPLICATE_FAILURE",
	PAGE_DUPLICATE: "PAGE_DUPLICATE",
	PAGE_DUPLICATE_SUCCESS: "PAGE_DUPLICATE_SUCCESS",
	PAGE_DUPLICATE_FAILURE: "PAGE_DUPLICATE_FAILURE",
	SET_EMAIL_DATA: "SET_EMAIL_DATA",
	SET_PAGE_DATA: "SET_PAGE_DATA",
	SET_PREVIEW_DATA: "SET_PREVIEW_DATA",
	RESET_PREVIEW_DATA: "RESET_PREVIEW_DATA",
	SET_PREVIEW_DRAWER_VISIBLE: "SET_PREVIEW_DRAWER_VISIBLE",
	SET_ASSET_TEMPLATE_DRAWER_VISIBLE: "SET_ASSET_TEMPLATE_DRAWER_VISIBLE",
	ASSET_TEMPLATE_SELECTED_LIST: "ASSET_TEMPLATE_SELECTED_LIST",
	CREATE_NEW_ASSET_TEMPLATE: "CREATE_NEW_ASSET_TEMPLATE",
	CREATE_NEW_ASSET_TEMPLATE_SUCCESS: "CREATE_NEW_ASSET_TEMPLATE_SUCCESS",
	CREATE_NEW_ASSET_TEMPLATE_FAILURE: "CREATE_NEW_ASSET_TEMPLATE_FAILURE",
	GET_ASSET_TEMPLATE_DATA: "GET_ASSET_TEMPLATE_DATA",
	GET_ASSET_TEMPLATE_DATA_SUCCESS: "GET_ASSET_TEMPLATE_DATA_SUCCESS",
	GET_ASSET_TEMPLATE_DATA_FAILURE: "GET_ASSET_TEMPLATE_DATA_FAILURE",
	UPDATE_ASSET_TEMPLATE_CONTENT: "UPDATE_ASSET_TEMPLATE_CONTENT",
	UPDATE_ASSET_TEMPLATE_CONTENT_SUCCESS:
		"UPDATE_ASSET_TEMPLATE_CONTENT_SUCCESS",
	UPDATE_ASSET_TEMPLATE_CONTENT_FAILURE:
		"UPDATE_ASSET_TEMPLATE_CONTENT_FAILURE",
	FETCH_TASKS: "FETCH_TASKS",
	FETCH_TASKS_SUCCESS: "FETCH_TASKS_SUCCESS",
	FETCH_TASKS_FAILURE: "FETCH_TASKS_FAILURE",
	GET_TASK_DATA: "GET_TASK_DATA",
	GET_TASK_DATA_SUCCESS: "GET_TASK_DATA_SUCCESS",
	GET_TASK_DATA_FAILURE: "GET_TASK_DATA_FAILURE",
	SET_TASK_DRAWER_VISIBLE: "SET_TASK_DRAWER_VISIBLE",
	TASK_SELECTED_LIST: "TASK_SELECTED_LIST",
	SET_TASK_TABLE_SORT_ORDER: "SET_TASK_TABLE_SORT_ORDER",
	SET_TASK_TABLE_SIZE: "SET_TASK_TABLE_SIZE",
	SET_TASK_TABLE_CURRENT_PAGE: "SET_TASK_TABLE_CURRENT_PAGE",
	DELETE_TASKS: "DELETE_TASKS",
	DELETE_TASKS_SUCCESS: "DELETE_TASKS_SUCCESS",
	DELETE_TASKS_FAILURE: "DELETE_TASKS_FAILURE",
	UPDATE_CURRENT_TASK_ID: "UPDATE_CURRENT_TASK_ID",
	CREATE_TASK: "CREATE_TASK",
	UPDATE_TASK: "UPDATE_TASK",
	CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
	CREATE_TASK_FAILURE: "CREATE_TASK_FAILURE",
	UPDATE_TASK_SUCCESS: "UPDATE_TASK_SUCCESS",
	UPDATE_TASK_FAILURE: "UPDATE_TASK_FAILURE",
	RESET_CURRENT_TASK: "RESET_CURRENT_TASK",
	SELECT_TEMPLATE_DRAWER_VISIBLE: "SELECT_TEMPLATE_DRAWER_VISIBLE",
	SET_EXISTING_TEMPLATE_DATA: "SET_EXISTING_TEMPLATE_DATA",
	GET_SELECT_TEMPLATE_LIST: "GET_SELECT_TEMPLATE_LIST",
	GET_SELECT_TEMPLATE_LIST_SUCCESS: "GET_SELECT_TEMPLATE_LIST_SUCCESS",
	GET_SELECT_TEMPLATE_LIST_FAILURE: "GET_SELECT_TEMPLATE_LIST_FAILURE",
	RESET_ASSET_TEMPLATE_DATA: "RESET_ASSET_TEMPLATE_DATA",
	SET_SELECT_TEMPLATE_CURRENT_PAGE: "SET_SELECT_TEMPLATE_CURRENT_PAGE",
	SET_SELECT_TEMPLATE_ID: "SET_SELECT_TEMPLATE_ID",
	FETCH_TASKS_TEMPLATE: "FETCH_TASKS_TEMPLATE",
	FETCH_TASKS_TEMPLATE_SUCCESS: "FETCH_TASKS_TEMPLATE_SUCCESS",
	FETCH_TASKS_TEMPLATE_FAILURE: "FETCH_TASKS_TEMPLATE_FAILURE",
	SET_TASK_TEMPLATE_TABLE_SORT_ORDER: "SET_TASK_TEMPLATE_TABLE_SORT_ORDER",
	SET_TASK_TEMPLATE_TABLE_SIZE: "SET_TASK_TEMPLATE_TABLE_SIZE",
	SET_TASK_TEMPLATE_TABLE_CURRENT_PAGE: "SET_TASK_TEMPLATE_TABLE_CURRENT_PAGE",
	SET_TASK_TEMPLATE_ID: "SET_TASK_TEMPLATE_ID",
	TASK_DUPLICATE_MODAL_VISIBLE: "TASK_DUPLICATE_MODAL_VISIBLE",
	DUPLICATE_TASK_TEMPLATE: "DUPLICATE_TASK_TEMPLATE",
	DUPLICATE_TASK_TEMPLATE_SUCCESS: "DUPLICATE_TASK_TEMPLATE_SUCCESS",
	DUPLICATE_TASK_TEMPLATE_FAILURE: "DUPLICATE_TASK_TEMPLATE_FAILURE",
	SET_ATTACHMENTS_DRAWER_VISIBLE: "SET_ATTACHMENTS_DRAWER_VISIBLE",
	REMOVE_EMAIL_UPLOADED_FILES: "REMOVE_EMAIL_UPLOADED_FILES",
	REMOVE_EMAIL_UPLOADED_FILES_SUCCESS: "REMOVE_EMAIL_UPLOADED_FILES_SUCCESS",
	REMOVE_EMAIL_UPLOADED_FILES_FAILURE: "REMOVE_EMAIL_UPLOADED_FILES_FAILURE",
	REMOVE_TASK_UPLOADED_FILES: "REMOVE_TASK_UPLOADED_FILES",
	REMOVE_TASK_UPLOADED_FILES_SUCCESS: "REMOVE_TASK_UPLOADED_FILES_SUCCESS",
	REMOVE_TASK_UPLOADED_FILES_FAILURE: "REMOVE_TASK_UPLOADED_FILES_FAILURE",
	SET_DELETE_ATTACHMENTS_MODAL_VISIBLE: "SET_DELETE_ATTACHMENTS_MODAL_VISIBLE",
	UPLOAD_ASSET_IMAGE: "UPLOAD_ASSET_IMAGE",
	UPLOAD_ASSET_IMAGE_TO_S3: "UPLOAD_ASSET_IMAGE_TO_S3",
	SET_ASSET_FILE_PROGRESS_STATUS: "SET_ASSET_FILE_PROGRESS_STATUS",
	SET_TEMPLATES_TAB_ACTIVE: "SET_TEMPLATES_TAB_ACTIVE",
	SET_TEMPLATE_TYPE: "SET_TEMPLATE_TYPE",
	ASSETS_SET_SEARCH_TEXT: "ASSETS_SET_SEARCH_TEXT",
	SET_PREVIEW_LOADER: "SET_PREVIEW_LOADER",
	SET_TICKETING_DRAWER_VISIBLE: "SET_TICKETING_DRAWER_VISIBLE",
	CREATE_TRELLO_ASSET: "CREATE_TRELLO_ASSET",
	FETCH_TICKETING: "FETCH_TICKETING",
	SET_TICKETING_ASSETS: "SET_TICKETING_ASSETS",
	TRELLO_ASSETS_SELECTED_LIST: "TRELLO_ASSETS_SELECTED_LIST",
	DELETE_TRELLO_ASSETS_LIST: "DELETE_TRELLO_ASSETS_LIST",
	SET_PROJECT_LIST: "SET_PROJECT_LIST",
	FETCH_PROJECTS: "FETCH_PROJECTS",
	SET_SLACK_DRAWER_VISIBLE: "SET_SLACK_DRAWER_VISIBLE",
	CREATE_SLACK_ASSET: "CREATE_SLACK_ASSET",
	UPDATE_SLACK_ASSET: "UPDATE_SLACK_ASSET",
	FETCH_SLACK_ASSETS: "FETCH_SLACK_ASSETS",
	SET_SLACK_ASSETS: "SET_SLACK_ASSETS",
	SLACK_ASSETS_SELECTED_LIST: "SLACK_ASSETS_SELECTED_LIST",
	DELETE_SLACK_ASSETS_LIST: "DELETE_SLACK_ASSETS_LIST",
	FETCH_TICKETING_MERGES: "FETCH_TICKETING_MERGES",
	SET_TICKETING_MERGES: "SET_TICKETING_MERGES",
	FETCH_MICROSOFT_USERS: "FETCH_MICROSOFT_USERS",
	SET_MICROSOFT_TEAM_DRAWER: "SET_MICROSOFT_TEAM_DRAWER",
	CREATE_MICROSOFT_TEAM_ASSET: "CREATE_MICROSOFT_TEAM_ASSET",
	FETCH_MICROSOFT_TEAM_ASSETS: "FETCH_MICROSOFT_TEAM_ASSETS",
	SET_MICROSOFT_TEAM_ASSETS: "SET_MICROSOFT_TEAM_ASSETS",
	MICROSOFT_TEAM_ASSETS_SELECTED_LIST: "MICROSOFT_TEAM_ASSETS_SELECTED_LIST",
	DELETE_MICROSOFT_TEAM_LIST: "DELETE_MICROSOFT_TEAM_LIST",
	UPDATE_MICROSOFT_TEAM_ASSET: "UPDATE_MICROSOFT_TEAM_ASSET",
	SET_SEND_SAMPLE_MODAL_VISIBLE: "SET_SEND_SAMPLE_MODAL_VISIBLE",
	SET_EMPLOYEE_SEARCH_LOADER: "SET_EMPLOYEE_SEARCH_LOADER",
	SEARCH_EMPLOYEES: "SEARCH_EMPLOYEES",
	SET_SEARCHED_EMPLOYEES: "SET_SEARCHED_EMPLOYEES",
	SEND_SAMPLE_ASSET: "SEND_SAMPLE_ASSET",
	SET_IS_SENDING_SAMPLE_ASSET: "SET_IS_SENDING_SAMPLE_ASSET",
};

export default actions;
