import React from "react";
import {
	FundProjectionScreenOutlined,
	SnippetsOutlined,
	LineChartOutlined,
	UserOutlined,
	RocketOutlined,
	FolderOpenOutlined,
	SettingOutlined,
	BuildOutlined,
} from "@ant-design/icons";
import rocket from "assets/images/rocket.svg";
import file from "assets/images/file.svg";
import portal from "assets/images/portal.svg";
import meetingCalender from "assets/images/meeting-calendar.svg";

const menus = [
	// {
	// 	key: "dashboard",
	// 	label: "sidebar.dashboard",
	// 	searchKey: "",
	// 	leftIcon: <LineChartOutlined />,
	// 	policy_name: "dashboard",
	// 	type: "icon",
	// },
	// {
	// 	key: "reports",
	// 	label: "sidebar.reports",
	// 	searchKey: "",
	// 	leftIcon: <FundProjectionScreenOutlined />,
	// 	policy_name: "dashboard",
	// 	type: "icon",
	// },
	{
		key: "journeys",
		label: "sidebar.journeys",
		leftIcon: <RocketOutlined />,
		searchKey: "journey_type=journey",
		policy_name: "journey",
		type: "icon",
	},
	{
		key: "employees",
		label: "sidebar.people",
		leftIcon: <UserOutlined />,
		searchKey: "employee_type=all_employees",
		policy_name: "employee",
		type: "icon",
	},
	// {
	// 	key: "portal",
	// 	label: "sidebar.portal",
	// 	leftIcon: <BuildOutlined />,
	// 	policy_name: "portal",
	// 	type: "icon",
	// },
	{
		key: "assets",
		label: "sidebar.templates",
		leftIcon: <FolderOpenOutlined />,
		searchKey: "asset_type=message",
		policy_name: "journey",
		type: "icon",
	},
	// {
	// 	key: "moments_that_matter",
	// 	label: "sidebar.moments",
	// 	searchKey: "",
	// 	leftIcon: file,
	// 	policy_name: "journey",
	// },
	// {
	// 	key: "meetings",
	// 	label: "sidebar.meetings",
	// 	leftIcon: meetingCalender,
	// 	searchKey: "meeting_type=meeting",
	// 	policy_name: "meeting",
	// },
	// {
	// 	key: "tasks",
	// 	label: "sidebar.yourTasks",
	// 	leftIcon: <SnippetsOutlined />,
	// 	searchKey: "",
	// 	policy_name: "tasks",
	// 	type: "icon",
	// },
	{
		key: "settings",
		label: "sidebar.settings",
		leftIcon: <SettingOutlined />,
		policy_name: "settings",
		type: "icon",
	},
];

const templateMenu = [
	{
		key: "templates",
		label: "sidebar.templates",
		leftIcon: portal,
		searchKey: "template_type=email",
	},
	{
		key: "meetings",
		label: "sidebar.meetings",
		leftIcon: meetingCalender,
		searchKey: "",
	},
	{
		key: "tasks",
		label: "sidebar.tasks",
		leftIcon: file,
		searchKey: "",
	},
	{
		key: "journeys",
		label: "sidebar.journeys",
		leftIcon: rocket,
		searchKey: "",
	},
];

export { menus, templateMenu };
